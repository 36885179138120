import * as React from "react"
import { FC, useContext } from "react"
import { LoginContext } from "social-supermarket-components"
import Layout from "../../social-supermarket/components/Layout"
import SEO from "../../social-supermarket/components/Seo"
import GiftListPage from "../../social-supermarket/pages/gifting/gift-list/GiftListPage"

interface Props {}

const GiftList: FC<Props> = () => {
  const loginContext = useContext(LoginContext)

  return loginContext.user ? (
    <Layout>
      <SEO title={"Gift Page"} description={""} />
      <GiftListPage />
    </Layout>
  ) : (
    <Layout />
  )
}

export default GiftList
