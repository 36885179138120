import * as React from "react"
import { FC, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import useQueryString from "../../../hooks/useQueryString"
import {
  FullLinkButton,
  IconWrapper,
  LoginContext,
  PrimaryButton,
  SideTabs,
  Spinner,
  Tab,
} from "social-supermarket-components"
import * as orderApi from "../../../api/platform/orderApi"
import OrderType from "../../../model/OrderType"
import SubMenuBar from "../../../components/header/SubMenuBar"
import GiftListPageDesigner from "./designer/GiftListPageDesigner"
import { GiftListContext } from "../../../context/GiftListProvider"
import GiftInvite from "./invite/GiftInvite"
import OrderItems from "../../orders/OrderItems"
import Cards from "../../../components/Cards"
import AllRecipientList from "../../orders/AllRecipientList"
import PendingApprovalRecipientList from "../../orders/PendingApprovalRecipientList"
import PaymentList from "../../orders/PaymentList"
import Card from "../../../components/Card"
import GiftVoucherConfig from "../../orders/GiftVoucherConfig"

const Container = styled.div`
  padding-top: 45px;
`
const Title = styled.div``
const RefreshButton = styled(FullLinkButton)`
  margin-left: auto;
`

const BackButton = styled(FullLinkButton)`
  margin-right: 5px;
`

const StyledSubMenuBar = styled(SubMenuBar)`
  padding: 10px;
  box-shadow: rgb(230 230 230) 0px -1px 0px 0px inset;
  font-size: 0.9em;
  display: flex;
  align-items: center;
`

const LinkBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  padding-bottom: 5px;
  margin-top: 10px;
`

const ConfirmButton = styled(PrimaryButton)`
  &&& {
    padding-left: 20px;
    padding-right: 20px;
  }
`
const EditProductsButton = styled(FullLinkButton)`
  i {
    padding-right: 20px;
  }
`

interface Props {}

const GiftListPage: FC<Props> = () => {
  const { token, initialising, user } = useContext(LoginContext)
  const { allGiftLists, updateGiftList, cancel, addChoices } = useContext(GiftListContext)
  const [giftListKey, setGiftListKey] = useQueryString("key", "")
  const [onlyJustCreated, setOnlyJustCreated] = useQueryString("created", "")
  const [confirmedProducts, setConfirmedProducts] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentTab, setCurrentTab] = useState<number>(0)

  const hasConfirmedProducts = confirmedProducts || !onlyJustCreated

  const thisGiftList: OrderType = allGiftLists
    ? allGiftLists.find(giftList => giftList.key === giftListKey)
    : null
  const recipients = thisGiftList?.recipients
  const pendingApproval = recipients
    ? recipients.filter(response => response.status === "pending_approval")
    : []

  useEffect(() => {
    if (giftListKey && !initialising) {
      if (!thisGiftList?.recipients) {
        fetchOrder()
      } else {
        setIsLoading(false)
      }
    }
  }, [giftListKey, initialising])

  const fetchOrder = async () => {
    try {
      setIsLoading(true)
      const { order } = await orderApi.getOrder(token, giftListKey)
      updateGiftList(order)
    } catch (e) {
      console.log(`Unexpected error, please contact support: ${e}`)
    } finally {
      setIsLoading(false)
    }
  }

  const waitAndFetch = () => {
    setIsLoading(true)
    setTimeout(() => {
      fetchOrder()
    }, 1000)
  }

  const handleUpdate = (giftList: OrderType) => {
    updateGiftList({ ...giftList, recipients })
  }

  const handleDesignSaved = (giftList: OrderType) => {
    updateGiftList({ ...giftList, recipients })
    if (onlyJustCreated) {
      setOnlyJustCreated("")
      setCurrentTab(2)
    }
  }

  const handleEditProducts = () => {
    cancel()
    addChoices(thisGiftList.items)
    navigate(`/gifting/update-options?key=${thisGiftList.key}`)
  }

  const handleConfirmProducts = () => {
    setConfirmedProducts(true)
    setCurrentTab(1)
  }

  const tabs = []
  if (thisGiftList) {
    if (pendingApproval?.length > 0) {
      tabs.push(
        <Tab
          title={`Pending Approval (${pendingApproval.length})`}
          id="pending-approval"
          alert
          borderBottom
        >
          <Cards>
            <PendingApprovalRecipientList
              recipients={pendingApproval}
              waitAndFetch={waitAndFetch}
              order={thisGiftList}
              refresh={fetchOrder}
            />
          </Cards>
        </Tab>
      )
    }
    tabs.push(
      <Tab title={"Products"} id="products">
        <LinkBar>
          <EditProductsButton onClick={handleEditProducts}>
            {onlyJustCreated && <IconWrapper name={"arrow left"} />} Edit products
          </EditProductsButton>
          {onlyJustCreated && (
            <ConfirmButton slim onClick={handleConfirmProducts}>
              Confirm Products <IconWrapper name={"arrow right"} />
            </ConfirmButton>
          )}
        </LinkBar>

        <Cards>
          <GiftVoucherConfig order={thisGiftList} updateList={handleUpdate} />
          <OrderItems order={thisGiftList} />
        </Cards>
      </Tab>
    )
    if (hasConfirmedProducts) {
      tabs.push(
        <Tab title={"Design"} id="design">
          <GiftListPageDesigner giftList={thisGiftList} updateList={handleDesignSaved} />
        </Tab>
      )

      if (thisGiftList.name) {
        tabs.push(
          <Tab title={"Invite"} id="invite" borderBottom>
            <GiftInvite
              giftList={thisGiftList}
              updateList={handleDesignSaved}
              fetchOrder={fetchOrder}
            />
          </Tab>
        )
        if (recipients?.length > 0) {
          tabs.push(
            <Tab title={`All Recipients (${recipients.length})`} id="all-recipients">
              <Cards>
                <AllRecipientList
                  order={thisGiftList}
                  responses={recipients}
                  refresh={fetchOrder}
                />
              </Cards>
            </Tab>
          )
        }

        if (thisGiftList?.payments?.length) {
          tabs.push(
            <Tab title={`Payments (${thisGiftList.payments.length})`} id="payments">
              <Cards>
                <Card>
                  <PaymentList payments={thisGiftList.payments} />
                </Card>
              </Cards>
            </Tab>
          )
        }
      }
    }
  }

  return (
    giftListKey && (
      <Container>
        <StyledSubMenuBar>
          <BackButton onClick={() => navigate("/gifting?tab=gift-pages")}>
            <IconWrapper name="angle left" />
            Your Gift Pages
          </BackButton>
          {thisGiftList && (
            <Title>
              <b>Gift Page:</b> {thisGiftList.name}
            </Title>
          )}
          <RefreshButton onClick={fetchOrder}>
            <IconWrapper name="sync" />
          </RefreshButton>
        </StyledSubMenuBar>
        <SideTabs currentTab={currentTab} setCurrentTab={setCurrentTab}>
          {tabs}
        </SideTabs>
        <Spinner isLoading={isLoading} label={"Loading Gift Page.."} />
      </Container>
    )
  )
}

export default GiftListPage
