import * as React from "react"
import { FC, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import OrderType from "../../../../model/OrderType"
import GiftListPageDesignerForm, {
  DEFAULT_PAGE_DESIGNER_FORM_TYPE,
  PageDesignerFormType,
} from "./GiftListPageDesignerForm"
import * as orderApi from "../../../../api/platform/orderApi"
import CardTitle from "../../../../components/CardTitle"
import Card from "../../../../components/Card"
import CardHelpText from "../../../../components/CardHelpText"
import { Icon } from "semantic-ui-react"
import { getOpenGiftSelectionUrl } from "../../../../util/generalUtil"
import { GlobalContext } from "../../../../context/GlobalProvider"
import ProductSelectionType from "../../../gift/choice/ProductSelectionType"
import {
  Buttons,
  FullLinkButton,
  LoginContext,
  PrimaryButton,
  Spinner,
} from "social-supermarket-components"
import {
  colors,
  DEFAULT_THEME_TYPE,
  ProductTaxonomyType,
} from "social-supermarket-model"

const Container = styled.div`
  padding: 20px 10px 20px 10px;
`
const CardContainer = styled(Card)`
  margin: auto;
  width: 100%;
  max-width: 1000px;
  min-height: 400px;
  position: relative;
  padding: 20px;
`
const PageDescription = styled(CardHelpText)`
  margin-bottom: 20px;
`

const PreviewButton = styled(FullLinkButton)`
  position: absolute;
  top: 15px;
  right: 15px;

  i {
    padding: 0 5px;
  }
`

interface Props {
  giftList: OrderType
  updateList: (giftList: OrderType) => void
}

const GiftListPageDesigner: FC<Props> = ({ giftList, updateList }) => {
  const { showGrowl } = useContext(GlobalContext)
  const { token } = useContext(LoginContext)
  const [isEditing, setIsEditing] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formState, setFormState] = useState<PageDesignerFormType>({
    ...DEFAULT_PAGE_DESIGNER_FORM_TYPE,
  })

  useEffect(() => {
    setFormState({
      title: giftList.name,
      description: giftList.description,
      giftNote: giftList.giftNote,
      logoSrc: giftList.logoSrc,
      bannerSrc: giftList.bannerSrc,
      metaFields: giftList.metaFields || [],
      categories: giftList.categories || [],
      theme: giftList.theme
        ? { ...DEFAULT_THEME_TYPE, controls: colors.primaryLight, ...giftList.theme }
        : { ...DEFAULT_THEME_TYPE, controls: colors.primaryLight },
    })
  }, [giftList])

  const categories: ProductTaxonomyType[] = getCategories(giftList.items)

  const handleSave = async event => {
    event.preventDefault()
    try {
      setIsLoading(true)

      if (formState.logoFile) {
        await orderApi.updateLogo(token, giftList.key, formState.logoFile)
      }

      if (!formState.logoFile && !formState.logoSrc) {
        await orderApi.removeLogo(token, giftList.key)
      }

      if (formState.bannerFile) {
        await orderApi.updateBanner(token, giftList.key, formState.bannerFile)
      }

      if (!formState.bannerFile && !formState.bannerSrc) {
        await orderApi.removeBanner(token, giftList.key)
      }

      console.log("UPDATING WITH CATEGORIES", formState.categories)
      const newList = await orderApi.updateDesign(
        token,
        giftList.key,
        formState.title,
        formState.description,
        formState.giftNote,
        formState.metaFields.filter(field => field),
        formState.categories,
        formState.theme
      )
      updateList(newList)
      setIsEditing(!isEditing)
      setIsEditing(false)

      showGrowl("Gift page successfully updated.")
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    } catch (e) {
      console.log(`Unexpected error, please contact support: ${e}`)
    } finally {
      setIsLoading(false)
    }
  }

  const handlePreview = () => {
    const openUrl = getOpenGiftSelectionUrl(giftList.key)
    window.open(openUrl, "_blank").focus()
  }

  return (
    <Container>
      <Spinner isLoading={isLoading} label={"Saving.."} />
      <CardContainer>
        <CardTitle>Design Your Gifting Page</CardTitle>
        <PageDescription>
          Design the page recipients will land on when they claim their gift.
        </PageDescription>

        <GiftListPageDesignerForm
          onChange={(name, value) => setFormState(prev => ({ ...prev, [name]: value }))}
          state={formState}
          validate={false}
          categories={categories}
        />
        {false && (
          <PreviewButton onClick={handlePreview}>
            View <Icon name={"external"} />
          </PreviewButton>
        )}
        <Buttons>
          <PrimaryButton slim onClick={handleSave} disabled={!formState.title}>
            Save
          </PrimaryButton>
        </Buttons>
      </CardContainer>
    </Container>
  )
}

const getCategories = (items: ProductSelectionType[]): ProductTaxonomyType[] => {
  const resultMap = {}
  items.forEach(item => {
    item.product.categories.forEach(category => {
      resultMap[category.slug] = category
    })
  })
  return Object.values(resultMap)
}

export default GiftListPageDesigner
